<template>
    <div class="h-100 p-relative">
        <div class="qf-search-filters px-2 pt-1 pb-2">
            <div class="card m-0">
                <b-form-group label="First Name:" class="pb-1">
                    <b-form-input
                        name="firstName"
                        v-model="form.firstName"
                    />
                </b-form-group>


                <b-form-group label="Last Name:" class="pb-1">
                    <b-form-input
                        name="lastName"
                        v-model="form.lastName"
                    />
                </b-form-group>

                <b-form-group label="Email:" class="pb-1">
                    <b-form-input
                        disabled
                        name="email"
                        v-model="form.email"
                    />
                </b-form-group>

                <b-form-group label="Phone Number:" class="pb-1">
                    <b-form-input
                        name="phoneNumber"
                        v-model="form.phoneNumber"
                    />
                </b-form-group>

                <b-form-group label="Avatar:">
                    <b-form-file
                        v-model="form.avatar"
                        accept="image/*"
                    />
                </b-form-group>
    
    
                <div v-if="activeAvatar">
                    <div class="pt-1">
                        <b-img :src="activeAvatar" class="qf-logo"></b-img>
                    </div>
                    <b-button class="my-1" size="sm" variant="success" @click="removeAvatar">
                        Remove Avatar
                    </b-button>
                </div>

                <b-button variant="primary" class="mb-1" @click="saveProfile">Save Changes</b-button>

                <b-button
                    variant="outline-primary"
                    class="mb-1"
                    @click="cancelModal()"
                >CANCEL
                </b-button>


            </div>
        </div>
    </div>
</template>

<script>
import {
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BImg,
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Parse from 'parse';
// import store from "@/store";
//import store from "@/store";
export default {
    name: "ModalEditMyProfile",
    components: {
        BFormGroup,
        BFormInput,
        BFormFile,
        BButton,
        BImg,
    },
    props: {
        profileToEdit: {
            type: Object,
            required: false,
            default: function () {
                return {}
            },
        }
    },
    data() {
        return {
            form: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",

            },
            profileAvatar: null,
            profileId: null,
        }
    },

    mounted() {
        let currentUser = Parse.User.current();
        if (currentUser) {
            this.currentUser = currentUser;
            this.curUserId = currentUser.id;
            this.form.firstName = currentUser.attributes.firstName;
            this.form.lastName = currentUser.attributes.lastName;
            this.form.email = currentUser.attributes.email;
            this.form.phoneNumber = currentUser.attributes.phoneNumber;
            this.profileAvatar = currentUser.attributes.avatar;
        }

        if (this.profileToEdit && this.profileToEdit.attributes) {
            this.form = JSON.parse(JSON.stringify(this.profileToEdit.attributes));
            this.profileId = this.profileToEdit.id;
        }


    },
    computed: {
        activeAvatar() {
            if (this.form.avatar) {
              //debugger;
                let selectedFile = this.form.avatar;
                return URL.createObjectURL(selectedFile);
            } else if (this.profileAvatar) {
              //debugger;
                return this.profileAvatar._url;
            } else {
                return null;
            }
        },
    },

    methods: {
        cancelModal() {
            this.$root.$emit('bv::toggle::collapse', 'cu-profile');
        },

        initials() {
            if (this.form.firstName) {
                return this.form.firstName.substring(0, 1) + this.form.lastName.substring(0, 1);
            } else {
                return "";
            }
        },

        removeAvatar() {
            //let self = this;
            this.currentUser.unset('avatar');
            this.profileAvatar = null;
            //this.form.avatar = null;
        },

        saveProfile() {
            let self = this;

            this.currentUser.set('firstName', this.form.firstName);
            this.currentUser.set('lastName', this.form.lastName);
            this.currentUser.set('email', this.form.email);
            this.currentUser.set('phoneNumber', this.form.phoneNumber);

            if (this.form.avatar) {
                let profAvatar = this.form.avatar;
                let parseFile = new Parse.File(profAvatar.name, profAvatar, profAvatar.type);
                this.currentUser.set('avatar', parseFile)
            }

            this.currentUser.save().then(function (newProf) {

                console.log("Edit profile:", newProf);

                self.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Profile saved!',
                        icon: 'EditIcon',
                        variant: 'success',
                    },
                });


                self.$emit('saved');
                self.cancelModal();

            }).catch(function (err) {
                self.$toast({
                    component: ToastificationContent,
                    props: {
                        title: err.message,
                        icon: 'CloseIcon',
                        variant: 'danger',
                    },
                });
                console.log("Save error: ", err.message);
            });
        },

    },
}
</script>

<style scoped>
.qf-logo{
    width: 30%;
}
.qf-cover-preview {
    width: 30%;
}
.qf-cover-preview img {
    width: 100%;
}
</style>
