<template>
    <div>
        <b-row>
            <b-col cols="12" xl="3" lg="4">
                <div>
                    <div class="d-flex justify-content-between align-items-center">
                        <h2>Profile Info</h2>
                        <feather-icon icon="EditIcon" v-b-toggle.cu-profile data-cy="editProfileIcon" size="18" class="text-success"/>
                    </div>
                    <b-sidebar
                        ref="editProfile"
                        id="cu-profile"
                        right
                        backdrop
                        shadow
                        sidebar-class="sidebar-lg"
                        bg-variant="white"
                        width="450px">
                        <template #header="{ hide }">
                          <div class="d-flex justify-content-between align-items-center content-sidebar-header w-100">
                            <h5 class="mb-0">Edit Profile</h5>
                            <div>
                              <feather-icon
                                  class="ml-1 cursor-pointer"
                                  icon="XIcon"
                                  size="16"
                                  @click="hide"
                              />
                            </div>
                          </div>
                        </template>
                        <modal-edit-my-profile @saved="updateUser"></modal-edit-my-profile>
                    </b-sidebar>
                    <b-card>
                        <b-media vertical-align="top" class="pb-1 d-flex align-items-center">
                            <template #aside>
                                <b-avatar size="64px" :text="initials" :src="avatarUrl"/>
                            </template>
                            <h4 class="media-heading">{{currentUser.attributes.firstName}} {{currentUser.attributes.lastName}}</h4>
                        </b-media>
                        <b-card-text>
                            <div class="mb-1">
                                <feather-icon icon="MailIcon" class="text-success mr-1" size="16" variant="success"/>
                                {{currentUser.attributes.email}}
                            </div>
                            <div>
                                <feather-icon icon="PhoneIcon" class="text-success mr-1" size="16"/>
                                {{currentUser.attributes.phoneNumber}}
                            </div>
                        </b-card-text>
                            <b-button @click="modalMode = 'changePass'" v-b-toggle.sidebarChangePassword data-cy="changePasswordButton" variant="success"> Change Password </b-button>
                    </b-card>
                </div>
            </b-col>
            <b-col cols="12" xl="9" lg="8">
                <div class="d-flex justify-content-between align-items-center">
                    <h2>Companies Managed</h2>
                    <b-button @click="setCompanyToEdit({})" v-b-toggle.cu-company data-cy="addCompanyButton" variant="primary" size="sm">
                        <feather-icon icon="PlusIcon" />
                        ADD COMPANY
                    </b-button>
                </div>
                <h4 v-if="companies==0">No companies are managed by the current user.</h4>
                <app-collapse>
                    <app-collapse-item v-for="(oneCompany, index) in companies" :key="index" title=""
                                       class="rounded mb-1">
                        <template #header>
                            <b-row class="d-flex align-items-center">
                                <b-img v-if="oneCompany.attributes.companyLogo"
                                       :src="oneCompany.attributes.companyLogo._url"
                                       height="42"
                                       width="42"
                                       rounded
                                       class="mx-1"

                                />
                                <b-img v-if="!oneCompany.attributes.companyLogo"
                                       :src="defaultLogo()"
                                       height="42"
                                       width="42"
                                       rounded
                                       class="bg-white mx-1"
                                />
                                <h4 id="companyName">{{oneCompany.attributes.companyName}}</h4>
                            </b-row>
                        </template>
                        <b-row class="py-1 border-top">
                            <b-col class="d-flex flex-column">
                                <small>Address:</small>
                                <div class="d-flex">
                                    <feather-icon icon="MapPinIcon" class="text-success mr-1" size="16"/>
                                    {{oneCompany.attributes.address}}
                                </div>
                            </b-col>
                            <b-col class="d-flex flex-column">
                                <small>VAT number:</small>
                                <div class="d-flex">
                                    <feather-icon icon="ClipboardIcon" class="text-success mr-1" size="16"/>
                                    {{oneCompany.attributes.vatNumber}}
                                </div>
                            </b-col>
                            <b-col class="d-flex flex-column">
                                <small>Email:</small>
                                <div class="d-flex">
                                    <feather-icon icon="MailIcon" class="text-success mr-1" size="16"/>
                                    {{oneCompany.attributes.email}}
                                </div>
                            </b-col>
                        </b-row>
                        <b-button v-b-modal.delete-modal size="sm" class="float-right ml-1 mb-1"
                                  @click="openModal(oneCompany)"
                                  variant="success">
                            Delete Company
                        </b-button>

                        <b-button  size="sm" class="float-right mb-1" variant="success"
                                   v-b-toggle.cu-company @click="setCompanyToEdit(oneCompany)">
                            Edit Company
                        </b-button>

                        <b-button size="sm" class="float-right mr-1 mb-1"
                                  @click="getInvoice(oneCompany)"
                                  v-b-toggle.sidebar-right
                                  variant="success">
                            Check Invoices
                        </b-button>
                    </app-collapse-item>

                </app-collapse>

            </b-col>
        </b-row>
        <b-modal
                id="delete-modal"
                ok-variant="danger"
                ok-title="Delete"
                cancel-title="Close"
                cancel-variant="outline-secondary"
                modal-class="modal-danger"
                size="sm"
                button-size="sm"
                centered
                title="Delete this Member"
                @ok="deleteCompany(companyToDelete)"
        >
            <b-card-text>
                Are you sure you want to delete {{companyToDelete.attributes.companyName}}?
            </b-card-text>
        </b-modal>
        <b-sidebar
                id="sidebar-right"
                right
                backdrop
                shadow
                sidebar-class="sidebar-lg"
                bg-variant="white"
                width="450px"
        >
            <template #header="{ hide }">
              <div class="d-flex justify-content-between align-items-center content-sidebar-header w-100">
                <div>
                  <feather-icon
                      class="ml-1 cursor-pointer"
                      icon="XIcon"
                      size="16"
                      @click="hide"
                  />
                </div>
              </div>
            </template>

            <div v-if="invoices!=0">
                <b-img :src="selCompany.attributes.companyLogo._url" rounded center class="mb-1 mb-sm-0" height="75"
                       width="75"></b-img>
                <h4 class="text-center mt-1">{{selCompany.attributes.companyName}}</h4>
                <b-card no-body class="mx-2 px-1 pt-1 rounded">
                    <div>
                        <div class="d-flex mb-1" variant="primary">
                            <feather-icon icon="MapPinIcon" class="text-success mr-1" size="18"/>
                            {{selCompany.attributes.address}}
                        </div>
                        <div class="d-flex mb-1" variant="primary">
                            <feather-icon icon="ClipboardIcon" class="text-success mr-1" size="18"/>
                            {{selCompany.attributes.vatNumber}}
                        </div>
                        <div class="d-flex mb-1" variant="primary">
                            <feather-icon icon="MailIcon" class="text-success mr-1" size="18"/>
                            {{selCompany.attributes.email}}
                        </div>
                    </div>
                </b-card>

                <h4 class="ml-2">Invoices</h4>
                <b-card no-body class="mx-2 px-1 pt-1 rounded">
                    <div class="border-bottom d-flex justify-content-between mb-1" variant="primary"
                         v-for="(oneInvoice, index) in invoices" :key="index">
                        <div class="d-flex">
                            <feather-icon icon="FileIcon" class="text-success mr-1" size="18"/>
                            <div>
                                <a :href="oneInvoice.attributes.invoicePdf._url"
                                   target="_blank">{{niceFileName(oneInvoice.attributes.invoicePdf._name)}}</a>
                                <p class="small">{{ niceDate(oneInvoice.attributes.date) }}</p>
                            </div>
                        </div>
                        <div class="align-right">
                            <strong>€ {{oneInvoice.attributes.total}}</strong>
                        </div>
                    </div>
                </b-card>
            </div>
            <div v-else>
                <h2 class="pt-1 text-center">There are no invoices for this company.</h2>
            </div>
        </b-sidebar>
        <b-sidebar
                ref="mySidebar"
                id="cu-company"
                right
                backdrop
                shadow
                width="450px"
                sidebar-class="sidebar-lg"
                bg-variant="white"
        >
            <template #header="{ hide }">
              <div class="d-flex justify-content-between align-items-center content-sidebar-header w-100">
                <h5 class="mb-0">Add/Edit Company</h5>
                <div>
                  <feather-icon
                      class="ml-1 cursor-pointer"
                      icon="XIcon"
                      size="16"
                      @click="hide"
                  />
                </div>
              </div>
            </template>
            <modal-edit-company v-if="selCompany && modalMode === 'editCompany'" :company-to-edit="selCompany"></modal-edit-company>
        </b-sidebar>
        <b-sidebar
            id="sidebarChangePassword"
            right
            backdrop
            shadow
            sidebar-class="sidebar-lg"
            bg-variant="white"
            width="450px"
        >
            <template #header="{ hide }">
              <div class="d-flex justify-content-between align-items-center content-sidebar-header w-100">
                <h5 class="mb-0">Change Password</h5>
                <div>
                  <feather-icon
                      class="ml-1 cursor-pointer"
                      icon="XIcon"
                      size="16"
                      @click="hide"
                  />
                </div>
              </div>
            </template>
            <modal-change-password v-if="modalMode === 'changePass'"></modal-change-password>
        </b-sidebar>
    </div>
</template>

<script>
    import {BCard, BRow, BButton, BCol, BCardText, BAvatar, BMedia, BImg, BSidebar, VBToggle, VBModal} from 'bootstrap-vue'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import Parse from 'parse';
    import moment from 'moment';
    import store from "@/store";
    import ModalEditCompany from "@/modals/ModalEditCompany";
    import ModalEditMyProfile from "@/modals/ModalEditMyProfile";
    import ModalChangePassword from "@/modals/ModalChangePassword";
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            AppCollapse,
            AppCollapseItem,
            BCard,
            BRow,
            BCol,
            BCardText,
            BButton,
            BAvatar,
            BMedia,
            BImg,
            BSidebar,
            ModalEditCompany,
            ModalEditMyProfile,
            ModalChangePassword,
        },

        directives: {
            'b-toggle': VBToggle,
            'b-modal': VBModal,
            Ripple,
        },

        data(){
            return {
                modalMode: '',
                selCompany: {},
                profile: {},
                invoices: [],
                companyToDelete: {
                    attributes: {}
                },
                currentUser: {
                  attributes: {}
                }
            }
        },

        mounted() {

            this.currentUser = Parse.User.current();

            if (this.currentUser) {
                this.profile = this.currentUser.attributes;
                //this.curUser = this.currentUser;

                const BCompany = Parse.Object.extend("BillingCompany");
                const query = new Parse.Query(BCompany);

                const userPointer =
                    {
                        "__type": "Pointer",
                        "className": "_User",
                        "objectId": this.currentUser.id
                    };

                query.equalTo("owner", userPointer);

                query.find().then(function (results) {
                    console.log(results);
                    store.commit('app/SET_COMPANIES', results);
                }).catch(function (exception) {
                    console.log(exception.message);
                });

            }

        },
        computed: {
            companies(){
                return store.getters['app/allCompanies'];
            },

            avatarUrl(){
                let urlString = "";
                if (this.currentUser.attributes.avatar) {
                    urlString = this.currentUser.attributes.avatar._url;
                }
                return urlString
            },

            initials(){
                if(this.profile.firstName){
                    return this.profile.firstName.substring(0, 1) + this.profile.lastName.substring(0, 1);
                }
                else{
                    return "";
                }
            },
            invoiceList(){
                return this.invoices;
            }
        },

        methods: {
            updateUser() {
              this.currentUser = {};
              this.currentUser = Parse.User.current();
              console.log("Current user: ", Parse.User.current());
            },
            openModal(oneMember){
                console.log('Company to delete: ', oneMember)
                this.companyToDelete = oneMember
            },
            deleteCompany(company){
                company.destroy().then(function(deletedCompany){
                    console.log(deletedCompany);
                    store.commit('app/REMOVE_COMPANY', company);
                })
                .catch(function (err) {
                    console.log("Parse error", err.message);
                });
            },
            setCompanyToEdit(companyObj){
                this.selCompany = companyObj;
                this.modalMode = 'editCompany';
            },
            niceDate(parseDate) {
                return moment(parseDate).format("DD-MM-YYYY");
            },
            niceFileName(filename){
                if (filename) {
                    return filename.substring(33);
                }
                else {
                    return "";
                }
            },
            getInvoice(companyObj){
                let self = this;
                this.selCompany = companyObj;

                const Invoices = Parse.Object.extend("Invoice");
                const query = new Parse.Query(Invoices);

                const companyPointer =
                    {
                        "__type": "Pointer",
                        "className": "BillingCompany",
                        "objectId": companyObj.id
                    };
                query.equalTo("company", companyPointer)

                query.find().then(function (results) {
                    console.log("Invoice query results: ", results);
                    self.invoices = results;
                }).catch(function (exception) {
                    console.log(exception.message);
                });
            },
        },
        watch: {
            companies: {
                immediate: true,
                deep: true,
                handler(newVal){
                    if (!newVal) {
                        let compQuery = new Parse.Query(Parse.Object.extend('BillingCompany'));
                        compQuery.get(this.$route.params.companyId).then(function (theCompany) {
                            store.commit('app/SET_COMPANIES', theCompany);
                        });
                    }
                }
            },
        },
        currentUser: {
          immediate: true,
          deep: true,
          handler(newVal){
            console.log("New value: ", newVal);
          }
        }
    }


</script>

<style>
#companyName {
    margin: 0;
    font-size: 18px;
}

.b-sidebar-header {
  background-color: #000013;
}
</style>
