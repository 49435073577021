<template>
    <div class="h-100 p-relative">
        <div v-if="true" class="qf-search-filters">
            <div class="card p-2 m-0">
                <validation-observer ref="changePassword">
                    <b-form-group label="New Password">
                        <div>
                            <label for="newPassword" class="pt-1">New Password <sup class="text-danger">*</sup></label>
                            <validation-provider name="password" v-slot="{ errors }" rules="required|min:8" vid="confirm">
                                <b-form-input name="password"
                                              type="password"
                                              id="newPassword"
                                              v-model="form.newPassword"
                                              placeholder="" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </div>
                        <div>
                            <label for="repeatNewPassword"  class="pt-1">Repeat New Password <sup class="text-danger">*</sup></label>
                            <validation-provider name="confirm password" v-slot="{ errors }" rules="required|min:8|confirmed:confirm">
                                <b-form-input name="password"
                                              type="password"
                                              id="repeatNewPassword"
                                              v-model="form.confirmNewPassword"
                                              placeholder="" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </div>
                    </b-form-group>
                </validation-observer>
                <b-button @click="saveAndValidate()" variant="primary" class="mb-1">SAVE</b-button>
                <b-button @click="cancelModal()"
                        variant="outline-primary"
                        class="mb-1"
                >CANCEL</b-button>
            </div>
        </div>

    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { required } from '@validations';
    import {
        //BForm,
        BFormGroup,
        BFormInput,
        //BFormSelect,
        //BFormSelectOption,
        //BFormCheckbox,
        BButton,
        //BBadge,
        //BFormTextarea,
        //BAvatar,
        //BImg,
    } from 'bootstrap-vue'
    import ToastificationContent from "@core/components/toastification/ToastificationContent";
    import Parse from 'parse';
    //import store from "@/store";
    export default {
        name: "ModalChangePassword",
        components: {
            //BForm,
            BFormGroup,
            BFormInput,
            //BFormSelect,
            //BFormSelectOption,
            //BFormCheckbox,
            BButton,
            //BBadge,
            //BFormTextarea,
            //VueAutosuggest,
            ValidationProvider,
            ValidationObserver
            //BAvatar,
            //BImg,

        },
        data() {
            return{
                required,
                form: {
                    newPassword:"",
                    confirmNewPassword:"",
                },
            }
        },
        mounted() {
            let currentUser = Parse.User.current();
            if(currentUser) {
                this.currentUser = currentUser;
                this.curUserId = currentUser.id;
            }
        },
        methods: {
            cancelModal(){
                this.$root.$emit('bv::toggle::collapse', 'cu-company');
            },
            saveAndValidate(){
                this.$refs.changePassword.validate().then(success => {
                    if (success) {
                        this.savePassword();
                    }
                }).catch(function(err){
                    console.log('validation errors', err);
                })
            },
            savePassword(){
                if (this.form.newPassword === this.form.confirmNewPassword){
                    // Parse.User.setPassword(this.newPassword)
                let curUser = Parse.User.current();
                    curUser.set('password',  this.form.newPassword);
                    curUser.save().then(function(saveResult){
                        console.log("Save result", saveResult);
                    }).catch(function(err)
                    {
                        console.log("error: ", err.message)
                    })
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Password Updated!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
